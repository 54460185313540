import zh from "./zh.json";
import en from "./en.json";
import th from "./th.json";

const resources = {
  zh: {
    translation: zh,
  },
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};

export default resources;
