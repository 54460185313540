import axios from "axios";
import qs from "qs";
import Cloud from "./cloud";
import CMS from "./cms";
import H5 from "./h5";
const Version = "/api/v2";
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { indices: false });
};
axios.interceptors.response.use((response) => {
    const { data } = response;
    return data;
}, (error) => {
    const { status } = error;
    if (status === 403) {
        throw new Error("Not Authenticate");
    }
    if (status === 500 || status === 502) {
        throw new Error("Server Error");
    }
    throw new Error(error);
});
export { Version, axios };
const $road = {
    CMS,
    Cloud,
    H5,
};
export default $road;
