import { axios, Version } from ".";
/**
 * 监测系统
 */
var CMS;
(function (CMS) {
    /**
     * 公司
     */
    let Inc;
    (function (Inc) {
        /**
         * 指定域名获取公司信息
         */
        Inc.Assign = ({ host }) => axios.get(`${Version}/company/current/`, {
            params: { host },
        });
    })(Inc = CMS.Inc || (CMS.Inc = {}));
    /**
     * 登录
     */
    let Login;
    (function (Login) {
        /**
         * 测试登录
         */
        Login.TestLogin = () => { };
        /**
         * 账号密码登录
         */
        Login.AccountLogin = ({ account, password, }) => axios.post(`${Version}/login/account/`, {
            account,
            password,
        });
        /**
         * 获取登录信息
         */
        Login.GetUserInfo = ({ session, }) => axios.get(`${Version}/login/userinfo/`, {
            headers: { session },
        });
    })(Login = CMS.Login || (CMS.Login = {}));
    /**
     * 农场
     */
    let Farm;
    (function (Farm) {
        /**
         * 农场列表
         */
        Farm.GetList = ({ session }) => axios.get(`${Version}/farm/`, {
            headers: { session },
        });
        /**
         * 农场详情
         */
        Farm.GetInfo = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/`, {
            headers: { session },
        });
        /**
         * 更新农场
         */
        Farm.Put = ({ session, farm_pk, farm }) => axios.put(`${Version}/farm/${farm_pk}/`, farm, { headers: { session } });
        /**
         * 设置农场操作密码
         */
        Farm.PutOperateSet = ({ session, farm_pk, farm, }) => axios.put(`${Version}/farm/${farm_pk}/`, farm, { headers: { session } });
        /**
         * 取消设置农场操作密码
         */
        Farm.PutOperateUnSet = ({ session, farm_pk, }) => axios.put(`${Version}/farm/${farm_pk}/`, { oppsw: null }, { headers: { session } });
        /**
         * 获取MQTT Token
         */
        Farm.GetMqttToken = ({ session, }) => axios.get(`${Version}/farm/getmqtttoken/`, {
            headers: { session },
        });
    })(Farm = CMS.Farm || (CMS.Farm = {}));
    /**
     * 农场主
     */
    let Farmer;
    (function (Farmer) {
        /**
         * 获取农场列表
         */
        Farmer.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/farmer/`, {
            headers: { session },
        });
        /**
         * 添加农场主
         */
        Farmer.Post = ({ session, farm_pk, farmer, }) => axios.post(`${Version}/farm/${farm_pk}/farmer/`, farmer, {
            headers: { session },
        });
        /**
         * 添加农场主（包含账号密码）
         */
        Farmer.PostAccount = ({ session, farm_pk, farmer, }) => axios.post(`${Version}/farm/${farm_pk}/farmer/`, farmer, {
            headers: { session },
        });
        /**
         * 农场主详情
         */
        Farmer.GetInfo = ({ session, farm_pk, farmer_pk, }) => axios.get(`${Version}/farm/${farm_pk}/farmer/${farmer_pk}/`, { headers: { session } });
        /**
         * 更新农场主
         */
        Farmer.Put = ({ session, farm_pk, farmer_pk, farmer, }) => axios.put(`${Version}/farm/${farm_pk}/farmer/${farmer_pk}/`, farmer, {
            headers: { session },
        });
        /**
         * 更新农场主（包含账号密码）
         */
        Farmer.PutAccount = ({ session, farm_pk, farmer_pk, farmer, }) => axios.put(`${Version}/farm/${farm_pk}/farmer/${farmer_pk}/`, farmer, {
            headers: { session },
        });
        /**
         * 删除农场主
         */
        Farmer.Delete = ({ session, farm_pk, farmer_pk, }) => axios.delete(`${Version}/farm/${farm_pk}/farmer/${farmer_pk}/`, {
            headers: { session },
        });
    })(Farmer = CMS.Farmer || (CMS.Farmer = {}));
    /**
     * 相册
     */
    let Album;
    (function (Album) {
        /**
         * 获取农场相册列表
         */
        Album.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/album/`, {
            headers: { session },
        });
        /**
         * //TODO 未实现
         * 上传照片
         */
        Album.Post = () => { };
        /**
         * 更新照片
         */
        Album.Put = ({ session, farm_pk, album_pk, album, }) => axios.put(`${Version}/farm/${farm_pk}/album/${album_pk}/`, album, {
            headers: { session },
        });
        Album.Delete = ({ session, farm_pk, album_pk, }) => axios.delete(`${Version}/farm/${farm_pk}/album/${album_pk}/`, {
            headers: { session },
        });
    })(Album = CMS.Album || (CMS.Album = {}));
    /**
     * 农场房间
     */
    let Room;
    (function (Room) {
        /**
         * 获取房间列表
         */
        Room.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/room/`, {
            headers: { session },
        });
        /**
         * 创建房间
         */
        Room.Post = ({ session, farm_pk, room, }) => axios.post(`${Version}/farm/${farm_pk}/room/`, room, {
            headers: { session },
        });
        /**
         * 获取房间详情
         */
        Room.GetInfo = ({ session, farm_pk, room_pk, }) => axios.get(`${Version}/farm/${farm_pk}/room/${room_pk}/`, {
            headers: { session },
        });
        /**
         * 修改房间
         */
        Room.Put = ({ session, farm_pk, room_pk, room, }) => axios.put(`${Version}/farm/${farm_pk}/room/${room_pk}/`, room, {
            headers: { session },
        });
        /**
         * 导出设备数据
         */
        Room.Export = ({ session, farm_pk, room_pk, device_pks, mintime, maxtime, }) => axios.post(`${Version}/farm/${farm_pk}/room/${room_pk}/export/`, {
            device_pks,
            mintime,
            maxtime,
        }, {
            headers: { session },
        });
    })(Room = CMS.Room || (CMS.Room = {}));
    /**
     * 农场设备
     */
    let Device;
    (function (Device) {
        /**
         * 获取农场设备列表
         */
        Device.GetList = ({ session, farm_pk, the_type, }) => axios.get(`${Version}/farm/${farm_pk}/device/`, { headers: { session }, params: { the_type } });
        /**
         * 获取摄像头列表
         */
        Device.GetCameraList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/camera/`, {
            headers: { session },
        });
        /**
         * 获取设备详细信息
         */
        Device.GetInfo = ({ session, farm_pk, device_pk, }) => axios.get(`${Version}/farm/${farm_pk}/device/${device_pk}/`, { headers: { session } });
        /**
         * 更新设备
         */
        Device.Put = ({ session, farm_pk, device_pk, device, }) => axios.put(`${Version}/farm/${farm_pk}/device/${device_pk}/`, device, {
            headers: { session },
        });
        /**
         * 获取设备最近数据
         */
        Device.GetDataRecent = ({ session, farm_pk, device_pk, }) => axios.get(`${Version}/farm/${farm_pk}/device/${device_pk}/datarecent/`, {
            headers: { session },
        });
        /**
         * 获取设备最近24小时数据
         */
        Device.GetDataHourly = ({ session, farm_pk, device_pk, }) => axios.get(`${Version}/farm/${farm_pk}/device/${device_pk}/datahourly/`, {
            headers: { session },
        });
        /**
         * 检索设备数据_最近数据
         */
        Device.GetDataTime = ({ session, farm_pk, device_pk, filter, }) => axios.get(`${Version}/farm/${farm_pk}/device/${device_pk}/search/`, {
            headers: { session },
            params: filter,
        });
        /**
         * 导出设备数据
         */
        Device.ExportDataTime = ({ session, farm_pk, device_pk, filter, }) => axios.get(`${Version}/farm/${farm_pk}/device/${device_pk}/export/`, {
            headers: { session },
            params: filter,
        });
    })(Device = CMS.Device || (CMS.Device = {}));
    /**
     * 操作设备
     */
    let Operate;
    (function (Operate) {
        /**
         * 开启设备
         */
        Operate.On = ({ session, farm_pk, device_pk, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/on/`, {}, { headers: { session } });
        /**
         * 开启设备（需要密码）
         */
        Operate.OnPassword = ({ session, farm_pk, device_pk, oppsw, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/on/`, {}, {
            headers: { session, oppsw },
        });
        /**
         * 关闭设备
         */
        Operate.Off = ({ session, farm_pk, device_pk, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/off/`, {}, { headers: { session } });
        /**
         * 关闭设备（需要密码）
         */
        Operate.OffPassword = ({ session, farm_pk, device_pk, oppsw, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/off/`, {}, {
            headers: { session, oppsw },
        });
        /**
         * 电机停止
         */
        Operate.Stop = ({ session, farm_pk, device_pk, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/stop/`, {}, { headers: { session } });
        /**
         * 电机停止（需要密码）
         */
        Operate.StopPassword = ({ session, farm_pk, device_pk, oppsw, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/stop/`, {}, {
            headers: { session, oppsw },
        });
        /**
         * 电机正转
         */
        Operate.Forward = ({ session, farm_pk, device_pk, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/forward/`, {}, { headers: { session } });
        /**
         * 电机正转（需要密码）
         */
        Operate.ForwardPassword = ({ session, farm_pk, device_pk, oppsw, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/forward/`, {}, {
            headers: { session, oppsw },
        });
        /**
         * 电机反转
         */
        Operate.Antiport = ({ session, farm_pk, device_pk, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/antiport/`, {}, { headers: { session } });
        /**
         * 电机反转（需要密码）
         */
        Operate.AntiportPassword = ({ session, farm_pk, device_pk, oppsw, }) => axios.post(`${Version}/farm/${farm_pk}/device/${device_pk}/antiport/`, {}, {
            headers: { session, oppsw },
        });
        /**
         * // TODO 未完成
         * 检查是否可添加设备
         */
        Operate.Check = () => { };
        /**
         * // TODO 未完成
         * 添加设备
         */
        Operate.Add = () => { };
        /**
         * // TODO 未完成
         * 获取阈值
         */
        Operate.MaxMin = () => { };
        /**
         * // TODO 未完成
         * 获取设备最近数据
         */
        Operate.DataRecent = () => { };
        /**
         * // TODO 未完成
         * 获取设备最近24小时数据
         */
        Operate.DataHourly = () => { };
    })(Operate = CMS.Operate || (CMS.Operate = {}));
    /**
     * 其他
     */
    let Other;
    (function (Other) {
        /**
         * 获取农场操作记录
         */
        Other.Log = ({ session, farm_pk, device_pk, }) => axios.get(`${Version}/farm/${farm_pk}/log/`, {
            headers: { session },
            params: { device_pk },
        });
    })(Other = CMS.Other || (CMS.Other = {}));
    /**
     * 告警记录
     */
    let Warning;
    (function (Warning) {
        /**
         * 获取农场告警记录
         */
        Warning.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/warning/`, {
            headers: { session },
        });
        /**
         * // TODO 未完成
         * 获取农场当前告警记录
         */
        Warning.GetCurrent = () => { };
        /**
         * // TODO 未完成
         * 告警受理
         */
        Warning.Accept = () => { };
    })(Warning = CMS.Warning || (CMS.Warning = {}));
    /**
     * 数据组
     */
    let DataGroup;
    (function (DataGroup) {
        /**
         * 获取数据组列表
         */
        DataGroup.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/datagroup/`, {
            headers: { session },
        });
        /**
         * 获取工控屏数据组列表
         */
        DataGroup.GetTVList = ({ session, farm_pk, }) => axios.get(`${Version}/farm/${farm_pk}/datagroup/?the_type=${20}`, {
            headers: { session },
        });
        /**
         * 获取数据组详细信息
         */
        DataGroup.GetInfo = ({ session, farm_pk, dg_pk, }) => axios.get(`${Version}/farm/${farm_pk}/datagroup/${dg_pk}/`, {
            headers: { session },
        });
    })(DataGroup = CMS.DataGroup || (CMS.DataGroup = {}));
})(CMS || (CMS = {}));
export default CMS;
