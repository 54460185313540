import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { farmStates, userStates } from "./stores";
// 获取 session 和 当前农场
export const useSessionFarm = () => {
    // session
    const session = useRecoilValue(userStates.sessionSelector);
    const farmList = useRecoilValue(farmStates.listState);
    // 农场
    const farm = useRecoilValue(farmStates.selState);
    const setFarm = useSetRecoilState(farmStates.selState);
    useEffect(() => {
        if (!farm && farmList.length > 0) {
            setFarm(farmList[0]);
        }
    }, [farm, farmList, setFarm]);
    return [session, farm];
};
