import { axios, Version } from ".";
/**
 * H5
 */
var H5;
(function (H5) {
    // 农场
    let FarmQuery;
    (function (FarmQuery) {
        FarmQuery.GetInfo = ({ farm_id }) => axios.get(`${Version}/open/farm/${farm_id}/`);
    })(FarmQuery = H5.FarmQuery || (H5.FarmQuery = {}));
    let DataQuery;
    (function (DataQuery) {
        DataQuery.GetList = ({ farm_id, agri_id, type, process, }) => axios.get(`${Version}/open/farm/${farm_id}/tracedata/`, {
            params: { agri_id, type, process },
        });
        DataQuery.GetUnit = () => axios.get(`${Version}/open/basedata/devicebasedata/`);
    })(DataQuery = H5.DataQuery || (H5.DataQuery = {}));
    // 农产品
    let PlantQuery;
    (function (PlantQuery) {
        PlantQuery.GetList = ({ farm_id }) => axios.get(`${Version}/open/farm/${farm_id}/plant/`);
    })(PlantQuery = H5.PlantQuery || (H5.PlantQuery = {}));
    // 扫码
    let QRCodeQuery;
    (function (QRCodeQuery) {
        QRCodeQuery.GetPlant = ({ farm_id, agri_id, }) => axios.get(`${Version}/open/farm/${farm_id}/plant/${agri_id}`);
        QRCodeQuery.GetUnit = ({ farm_id, agri_id, }) => axios.get(`${Version}/open/farm/${farm_id}/unit/${agri_id}`);
    })(QRCodeQuery = H5.QRCodeQuery || (H5.QRCodeQuery = {}));
    // 日志
    let LogQuery;
    (function (LogQuery) {
        LogQuery.GetWarning = ({ farm_id }) => axios.get(`${Version}/open/farm/${farm_id}/warning/`);
        LogQuery.GetOperating = ({ farm_id, }) => axios.get(`${Version}/open/farm/${farm_id}/log/`);
    })(LogQuery = H5.LogQuery || (H5.LogQuery = {}));
    // 直播
    let LiveQuery;
    (function (LiveQuery) {
        LiveQuery.Get = ({ farm_id }) => axios.get(`${Version}/open/farm/${farm_id}/camera/alive/`);
    })(LiveQuery = H5.LiveQuery || (H5.LiveQuery = {}));
})(H5 || (H5 = {}));
export default H5;
